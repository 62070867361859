
import Navbar from '../Components/Navbar';
import HeroImg2 from '../Components/HeroImg2';
import Footer from '../Components/Footer';
import React from 'react'
import Pricing from '../Components/Pricing';
import PricingData from '../Components/PricingData';
import WorkCard from '../Components/WorkCard';
import './project.css';

const Project = () => {
  const elementData = PricingData.map(item=>{
    return(
    <Pricing 
    category={item.category}
    price={item.price}
    delivery={item.delivery}
    pages={item.pages}
    Feature={item.Feature}
    FeatureOn={item.FeatureOn} 
    bt={item.bt}
    />
    )
  })
  return (
    <div className='hello'>
      <Navbar />
      <HeroImg2  heading="PROJECTS" text="Some of my most recent works" />
      <WorkCard />
      <div className="pro">
     {elementData}
      </div>
     
      <Footer />
    </div>
  )
}

export default Project
