import './Hero.css';
import React from 'react';
import top from '../assets/top.jpg'
import { Link } from 'react-router-dom';


const Hero = () => {
  return (
    <div className='hero'>
    <div className="mask">
    <img  src={top} className='into-img' alt='logo'/>
    </div>
      <div className="content">
       <p>HI, I'M A FREELANCER</p> 
       <h1>React Developer</h1>
       <div>
        <Link to="/project" className='btn'>PROJECTS</Link>
        <Link to="/contact" className='btn btn-light'>CONTACT</Link>
       </div>
      </div>
    </div>
  )
}

export default Hero;
