import './Pricing.css';
import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = (props) => {
  return (
    <div className='pricing'>
    <div className="container">
    <div className="card">
        <h3>{props.category}</h3>
        <span className='bar'></span>
        <p className="btc">{props.price}</p>
        <p>{props.delivery}</p>
        <p>{props.pages}</p>
        <p>{props.Feature}</p>
        <p className='respo'>{props.FeatureOn}</p>
        <Link to="/contact" className="btn">{props.bt}</Link>
    </div>
    </div>
    </div>
    
  )
}

export default Pricing;
