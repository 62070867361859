

const PricingData = [{
    category :"-Basic-",
    price:"$100",
    delivery:"-3 Days-",
    pages:"-3 Pages-",
    Feature:"--Featured--",
    FeatureOn:"--Responsive Design--",
    bt:"PURCHASE NOW"
},

{
    category :"-Premium-",
    price:"$200",
    delivery:"-2 Days-",
    pages:"-5 Pages-",
    Feature:"--Featured--",
    FeatureOn:"--Responsive Design--",
    bt:"PURCHASE NOW"
},
{
    category:"-Business-",
    price:"$300",
    delivery:"-5 Days-",
    pages:"-8 Pages-",
    Feature:"--Featured--",
    FeatureOn: "--Responsive Design--",
    bt:"PURCHASE NOW"

},
{
    category:"-Business-",
    price:"$300",
    delivery:"-5 Days-",
    pages:"-8 Pages-",
    Feature:"--Featured--",
    FeatureOn: "--Responsive Design--",
    bt:"PURCHASE NOW"
}];

export default PricingData;