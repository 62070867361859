
import './Footer.css';
import React from 'react';
import{FaHome,FaPhone, FaMailBulk, FaFacebook, FaInstagram, FaLinkedin, } from 'react-icons/fa';

const Footer = () =>{
  return (
    <div className='footer'>
    <div className="footer-container">
        <div className="left">
        <div className="location">
          <FaHome size={20} style={{color:"#fff",marginRight:"2rem"}} /> 
          <div>
            <p>Tinkune, Kathmandu</p>
            <p> Nepal</p> 
        </div>
        </div>
        <div className="phone">
        <h4><FaPhone size={20} style={{color:"#fff",marginRight:"2rem"}}/> +977 - 9866514011</h4></div>
         <div className="email">
        <h4><FaMailBulk size={20} style={{color:"#fff",marginRight:"2rem"}}/>panditbhuwan2@gmail.com</h4>
        </div> 
        </div>
        <div className="right">
            <h4>About the company</h4>
            <p>This is Bhuwan Pandit. I enjoy discussing new projects and design challenges.</p>
            <div className="social">
               <FaFacebook size={30} style={{color:"#fff",marginRight:"2rem"}}/>
               <FaInstagram size={30} style={{color:"#fff",marginRight:"2rem"}}/>
               <FaLinkedin size={30} style={{color:"#fff",marginRight:"2rem"}}/> 
            </div>
        </div>
    </div>
    </div>
  )}

export default Footer;

