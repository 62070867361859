
import React from 'react'
import Navbar from '../Components/Navbar';
import Hero from '../Components/Hero';
import Footer from '../Components/Footer';

const Home = () => {
  return (
    <div>
     <Navbar />
     <Hero />
     <Footer />
    </div>
  )
}

export default Home;
