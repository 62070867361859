import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import React from 'react'
import HeroImg2 from '../Components/HeroImg2';
import Form from '../Components/Form';

const Contact = () => {
  return (
    <div>
    <Navbar />
    <HeroImg2 heading="CONTACT." text="Lets connect now"/>
    <Form />
    <Footer />
    </div>
  )
}

export default Contact
